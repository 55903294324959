import React, { useState, useEffect, useContext } from 'react';

export const screenContext = React.createContext();
export const useData = () => useContext(screenContext);
export const DataProvider = ({ children, ...initOptions }) => {
	const [color, setColor] = useState('#074078');
	const exports = {
		color,
		setColor,
	};
	return (
		<screenContext.Provider value={exports}>{children}</screenContext.Provider>
	);
};
