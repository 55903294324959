import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function RSS({ url, headline }) {
	const [messages, setMessages] = useState([]);
	useEffect(() => {
		axios
			.get(
				`https://api.rss2json.com/v1/api.json?rss_url=${encodeURI(
					'https://www.solinger-tageblatt.de/arc/outboundfeeds/rss/'
				)}`
			)
			.then((response) => {
				if (response.data.items) {
					setMessages(response.data.items.splice(0, 8));
				}
			});
	}, []);
	return (
		<div className="spalte RSS" style={{ padding: '100px' }}>
			<h1>
				{headline} | {new Date().toLocaleDateString()}
			</h1>
			<div
				classname="rss-grid"
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr 1fr',
				}}
			>
				{messages &&
					messages.map((message, idx) => {
						console.log(message);
						console.log(message);
						return (
							<div
								key={idx}
								style={{
									border: '1px solid #CCC',

									padding: '2rem',
								}}
							>
								<span style={{ fontSize: '24px', color: '#999' }}>
									{message.pubDate}
								</span>
								<br />
								<span style={{ fontSize: '2rem' }}>{message.title}</span>
							</div>
						);
					})}
			</div>
		</div>
	);
}

RSS.defaultProps = {
	headline: 'Aktuelle Meldungen',
};
