import React from 'react';
import SWSIcon from '../assets/sws-icon.svg';
import { useData } from '../context';

export default function Layout(props) {
	const { color } = useData();
	return (
		<div style={{ backgroundColor: color }} id="Layout">
			<div className="background"></div>
			<div className="logo">
				<img src={SWSIcon} alt="S" />
			</div>
			<div className="content">{props.children}</div>
		</div>
	);
}
