import React from 'react';

export default function PDF({ pdf }) {
	return (
		<div
			className="spalte bild contain"
			style={{ backgroundImage: 'url(' + pdf + ')' }}
		></div>
	);
}
