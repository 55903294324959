import React from 'react';

export default function Loader() {
	return (
		<svg
			id="Loader"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 100 100"
			enableBackground="new 0 0 100 100"
		>
			<circle
				fill="none"
				stroke="#005a91"
				strokeWidth="4"
				strokeMiterlimit="10"
				cx="50"
				cy="50"
				r="48"
			></circle>
			<line
				fill="none"
				strokeLinecap="round"
				stroke="#005a91"
				strokeWidth="4"
				strokeMiterlimit="10"
				x1="50"
				y1="50"
				x2="85"
				y2="50.5"
				transform="rotate(87.1079 50 50)"
			>
				<animateTransform
					attributeName="transform"
					dur="2s"
					type="rotate"
					from="0 50 50"
					to="360 50 50"
					repeatCount="indefinite"
				></animateTransform>
			</line>
			<line
				fill="none"
				strokeLinecap="round"
				stroke="#005a91"
				strokeWidth="4"
				strokeMiterlimit="10"
				x1="50"
				y1="50"
				x2="49.5"
				y2="74"
				transform="rotate(299.614 50 50)"
			>
				<animateTransform
					attributeName="transform"
					dur="15s"
					type="rotate"
					from="0 50 50"
					to="360 50 50"
					repeatCount="indefinite"
				></animateTransform>
			</line>
		</svg>
	);
}
