import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import config from '../config';
import { Layout, Loader, Meldungen, Error } from '../components/';
import { useData } from '../context';
export default function Preview() {
	const { id, nonce, client } = useParams();
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const [authorized, setAuthorized] = useState(false);
	const [error, setError] = useState();
	const fetchPreview = () => {
		setLoading(true);
		setError('');
		Axios.post(config.url + '/preview', {
			id: id,
			nonce: nonce,
			client: client,
		})
			.then((response) => {
				if (response.data) {
					setAuthorized(true);
					setLoading(false);
					setData(response.data);
					console.log(response.data);
				}
			})
			.catch((error) => {
				setAuthorized(false);
				setError(error.response && error.response.data.message);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchPreview();
	}, [authorized]);

	let Content;
	if (!authorized) Content = '';
	if (error) Content = <Error message={error} />;
	if (loading) Content = <Loader />;
	if (data) Content = <Meldungen data={data} />;

	return <Layout>{Content}</Layout>;
}
