import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Meldung, NewsTicker } from './';

export default function Meldungen(props) {
	const { posts, ticker } = props.data;
	return (
		<div id="Meldungen">
			<Carousel
				showThumbs={false}
				showArrows={false}
				autoPlay={true}
				transitionTime={3000}
				interval={30000}
				infiniteLoop={true}
				showStatus={false}
			>
				{posts ? (
					posts.map((meldung, key) => {
						return (
							<Meldung
								ticker={ticker ? true : false}
								key={key}
								data={meldung}
							/>
						);
					})
				) : (
					<div
						className="meldung"
						style={{ color: '#FFF', alignItems: 'center' }}
					>
						Aktuell keine Meldungen vorhanden.
					</div>
				)}
			</Carousel>
			{ticker && <NewsTicker data={ticker} />}
		</div>
	);
}
