import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import config from "../config";
import { Login } from "./";
import { Layout, Loader, Meldungen } from "../components/";
import { useData } from "../context";
import { Offline, Online } from "react-detect-offline";

export default function Screens() {
  const { setColor } = useData();

  const { client } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState();

  const fetchScreen = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    if (token && token !== "") {
      setLoading(true);
      setError("");
      client &&
        Axios.post(config.url + "/data", {
          client: client,
          key: token,
        })
          .then((response) => {
            if (response.data) {
              console.log(response.data);
              setData(response.data);
              setAuthorized(true);
              setLoading(false);
              setColor(response.data.farbe);
            }
          })
          .catch((error) => {
            setAuthorized(false);
            console.log(error.response.code);
            setError(error.response && error.response.data.message);
            setLoading(false);
            if (error.response && error.response.data.code === "broke") {
              localStorage.removeItem("sws_token", "");
            }
          });
    }
  };

  useEffect(() => {
    fetchScreen();
    setInterval(function () {
      fetchScreen();
    }, 30000);
  }, [client]);
  let Content;
  if (loading) Content = <Loader />;
  if (!authorized)
    return (
      <Layout>
        <Login fetchScreen={fetchScreen} error={error} />
        <Offline>
          <div className="offline">
            Achtung: Dieses Gerät hat kein Internet.
          </div>
        </Offline>
        )}
      </Layout>
    );
  if (!data) Content = "";
  if (data) Content = <Meldungen data={data} />;

  return (
    <Layout>
      {Content}
      <Offline>
        <div className="offline">Achtung: Dieses Gerät hat kein Internet.</div>
      </Offline>
    </Layout>
  );
}
