import React from 'react';
import { Flexibel } from './vorlagen';

export default function Meldung({ data, ticker }) {
	let Vorlage = (
		<Flexibel
			ticker={ticker}
			content={data.content}
			colored_background={data.colored_background}
		/>
	);
	const meldungStyle = {
		backgroundColor: data.bereich_farbe,
	};
	const countImages = (content) => {
		let i = 0;
		content.forEach((zeile) => {
			zeile.forEach((spalte) => {
				if (spalte.typ === 'bild') {
					i++;
				}
			});
		});
		if (i > 0) return true;
		return false;
	};
	data.content.forEach((content) => {});
	const style = (
		<style
			dangerouslySetInnerHTML={{
				__html: `#meldung${data.id} .z-1 .spalte {align-self:center;}`,
			}}
		></style>
	);
	return (
		<div className="meldung" id={'meldung' + data.id} style={meldungStyle}>
			<div className="header">
				<img src={data.bereich_icon} alt="icon" />
				{data.bereich_title}
			</div>
			{Vorlage}
			<style
				dangerouslySetInnerHTML={{
					__html: `#meldung${data.id} h1 {color:${data.bereich_farbe}}`,
				}}
			></style>
			{countImages(data.content) && style}
		</div>
	);
}
