import React, { useState } from 'react';

export default function Login(props) {
	const [token, setToken] = useState();
	function saveToken(token) {
		localStorage.setItem('sws_token', token);
		props.fetchScreen();
	}
	return (
		<div id="Login">
			<label htmlFor="token">Sicherheitsschlüssel eingeben:</label>
			<input
				onChange={(e) => setToken(e.target.value)}
				type="text"
				name="token"
				id="token"
			/>
			<button onClick={(e) => saveToken(token)} type="submit">
				Token speichern
			</button>
			<div className="error">{props.error}</div>
		</div>
	);
}
