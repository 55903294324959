import React from 'react';
import { Bild, Text, Video, PDF, RSS } from './';

export default function Flexibel({ content, colored_background, ticker }) {
	const Zeile = ({ data }) => {
		return (
			<div className={'zeile s-' + data.length}>
				{data
					? data.map((spalte, idx) => {
							return <Spalte key={'spalte' + idx} data={spalte} />;
					  })
					: ''}
			</div>
		);
	};

	const Spalte = ({ data }) => {
		if (data.typ === 'text') {
			return <Text text={data.text} />;
		}
		if (data.typ === 'bild') {
			return <Bild cover={data.cover} bild={data.bild} />;
		}
		if (data.typ === 'video') {
			return <Video video={data.video} />;
		}
		if (data.typ === 'pdf') {
			return <PDF pdf={data.pdf} />;
		}
		if (data.typ === 'RSS') {
			return <RSS url={data.url} headline={data.headline} />;
		}
		return '';
	};
	let additionalClass = '';
	if (colored_background) {
		additionalClass = ' colored';
	}
	return (
		<div
			style={{
				marginBottom: ticker ? '120px' : '50px',
				maxHeight: ticker ? 'calc(100vh - 250px)' : '100vh',
			}}
			className={'template flexibel z-' + content.length + additionalClass}
		>
			{content &&
				content.map((zeile, idx) => {
					return <Zeile key={'zeile' + idx} data={zeile} />;
				})}
		</div>
	);
}
