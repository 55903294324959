import React from 'react';

export default function Bild({ cover, bild }) {
	if (cover) {
		return (
			<div
				className="spalte bild cover"
				style={{ backgroundImage: 'url(' + bild + ')' }}
			></div>
		);
	}
	return (
		<div
			className="spalte bild contain"
			style={{ backgroundImage: 'url(' + bild + ')' }}
		></div>
	);
}
