const dev = {
	url: 'http://swscms.local/wp-json/swscms/v1',
	apikey: 123456,
	interval: 5000,
};

const production = {
	url: 'https://www.sws-cms.de/wp-json/swscms/v1',
	apikey: 123456,
	interval: 60000,
};
const config = process.env.NODE_ENV === 'production' ? production : production;
export default config;
