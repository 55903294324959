import React from 'react';
import Ticker from 'react-ticker';

export default function NewsTicker({ data }) {
	const Meldungen = () => {
		return (
			<div style={{ whiteSpace: 'nowrap' }}>
				<span style={{ margin: '0 50px' }}>+++</span>
				{data}
			</div>
		);
	};

	return <Ticker>{({ index }) => <Meldungen />}</Ticker>;
}
