import React from 'react';

export default function Text({ text }) {
	function getFontSize(text) {
		const wordCount = text.split(' ').length;

		let fontSize = '30px';
		return fontSize;
	}
	return (
		<div style={{ fontSize: getFontSize(text) }} className="spalte ">
			<div className="text" dangerouslySetInnerHTML={{ __html: text }}></div>
		</div>
	);
}
