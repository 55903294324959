import React from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Preview, Screens } from './pages';

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/preview/:nonce/:bereich/:id" component={Preview}></Route>
				<Route path="/preview/:nonce/:client/" component={Preview}></Route>
				<Route path="/screen/:client" component={Screens}></Route>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
